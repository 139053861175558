export default class extends Marko.Component {
  onMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const requestRefresh = urlParams.get('refresh');
    if(requestRefresh) {
      const parent = window.opener || window.parent;
      parent.postMessage(['refresh'], '*');
    }
  }
};
